module.exports = {
  siteTitle: 'Carrer @ Not Not Consulting', // <title>
  manifestName: 'NotNot',
  manifestShortName: 'Landing', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#A0008C',
  manifestThemeColor: '#A0008C',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/images/notnot.png',
  pathPrefix: `/`, // This path is subpath of your hosting https://domain/portfolio
  // social
  socialLinks: [
    {
      icon: 'fa-github',
      name: 'Github',
      url: 'https://github.com/notnotse',
    },
    {
      icon: 'fa-linkedin-in',
      name: 'Linkedin',
      url: 'https://www.linkedin.com/company/22293497',
    },
    {
      icon: 'fa-twitter',
      name: 'Twitter',
      url: 'https://twitter.com/notnotse',
    }
  ],
};
