import React from 'react';

import Layout from '../components/Layout';
//import Header from '../components/Header';
import Scroll from '../components/Scroll';
import Footer from '../components/Footer';
import Social from '../components/Social';

import demo1 from '../assets/images/cat-laser.gif';
import notnotlogo from '../assets/images/notnot.png';
import notnotlogowhite from '../assets/images/notnotwhite.png';

import gplay from '../assets/images/google-play-badge.svg';
import astore from '../assets/images/app-store-badge.svg';

const IndexPage = () => (
  <Layout>
    {/*<Header />*/}

    <header className="masthead">
      <div className="container h-100">
        <div className="row h-100">
          <div className="col-lg-7 my-auto mx-auto">
            <div className="header-content mx-auto">
              <h1 className="mb-5">
              <img src={notnotlogowhite} className="img-fluid" alt="" />
                Jobba på Not Not Consulting? Nu söker vi engagerade medarbetare inom systemutveckling!
              </h1>
              <Scroll type="id" element="readmore">
                <a href="#readmore" className="btn btn-outline btn-xl">
                  Läs mer!
                </a>
              </Scroll>
            </div>
          </div>
        </div>
      </div>
    </header>

    <section className="download bg-primary text-center" id="readmore">
      <div className="container">
        <div className="row">
          <div className="col-md-8 mx-auto">
            <h2 className="section-heading">
              Gillar du programmering och utmaningar?
            </h2>
            <p className="text-muted">
            Vi levererar anpassade kundlösningar oftast byggda med öppen källkod tillsammans med tjänster från olika molnleverantörer. 
            </p>
          </div>
        </div>
      </div>
    </section>

    <section className="features" id="features">
      <div className="container">
        <div className="section-heading text-center">
          <h2>Kvalifikationer</h2>
          <p className="text-muted">
            Inte nödvändigt, men bra med erfarenhet inom något av följande:
          </p>
          <hr />
        </div>
        <div className="row">
          <div className="col-lg-4 my-auto">
            <div className="device-container">
              <div className="device-mockup iphone6_plus portrait white">
                <div className="device">
                  <div className="screen">
                    <img src={demo1} style={{height:"100%"}} alt="" />
                  </div>
                  <div className="button"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-8 my-auto">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-6">
                  <div className="feature-item">
                    <i className="icon-screen-smartphone text-primary"></i>
                    <h3>Programmeringsspråk</h3>
                    <p className="text-muted">
                      JavaScript, React, Elm, React Native, Java.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="feature-item">
                    <i className="icon-camera text-primary"></i>
                    <h3>Teknikstack</h3>
                    <p className="text-muted">
                      Linux, Docker, PostgreSQL, PostGIS
                    </p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <div className="feature-item">
                    <i className="icon-present text-primary"></i>
                    <h3>Cloud Providers</h3>
                    <p className="text-muted">
                      GCP, Azure, AWS
                    </p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="feature-item">
                    <i className="icon-lock-open text-primary"></i>
                    <h3>Open Source</h3>
                    <p className="text-muted">
                      Vi gillar öppenkällkod, hoppas du gör det med!
                    </p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <div className="feature-item">
                    <i className="icon-present text-primary"></i>
                    <h3>Buzz words</h3>
                    <p className="text-muted">
                      GraphQL, Kafka Streams, Firebase, Real-Time-Streaming, CI/CD, PWA
                    </p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="feature-item">
                    <i className="icon-lock-open text-primary"></i>
                    <h3>Arbetsmiljö</h3>
                    <p className="text-muted">
                      Hemma, Borta eller på kontoret. Eller hur du vill....
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    {/* <section className="bg-primary text-center" id="social">
      <div className="container">
        <div className="row">
          <div className="col-md-8 mx-auto">
            <h2 className="section-heading">
              Socialt
            </h2>
            <p className="text-muted">
            Vi levererar anpassade kundlösningar oftast byggd med öppen källkod tillsammans med tjänster från olika molnleverantörer. 
            </p>
          </div>
        </div>
      </div>
    </section> */}

    <section className="cta">
      <div className="cta-content">
        <div className="container">
          <h2>
            Stop waiting.
            <br />
            Start building.
          </h2>
                    <a target="_blank" href="https://docs.google.com/forms/d/e/1FAIpQLSf5F2wxM4vAemKHTLEGLn8R6NZFHVwcdjotVOv2rb22oRiCbw/viewform" className="btn btn-outline btn-xl">
                  Skicka ansökan!
                </a>
        </div>
      </div>
      <div className="overlay"></div>
    </section>

    <section className="contact bg-primary" id="contact">
      <div className="container">
        <h2>
          We 
          <i className="fas fa-heart"></i>
           new friends!
        </h2>
        <Social />


      </div>
    </section>

    <Footer />
  </Layout>
);

export default IndexPage;
